<template>
  <div class="alarm-report">
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-left flex flex-wrap">
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="!toggle_log ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="toggle_log = false">
            {{ $t("alarm_reminder_page.operation") }}
          </div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="toggle_log ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="toggle_log = true">
            {{ $t("alarm_reminder_page.log") }}
          </div>
        </div>
      </div>
      <div class="w-full mt-6 flex">
        <div class="w-full">
          <vue-good-table
            v-if="!toggle_log"
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 20,
              perPageDropdown: [20, 50, 100],
              dropdownAllowAll: false,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <button v-if="props.column.field == 'activate'" class="btn-blue-outline mr-2" @click="show_unit('email-reminder', props.row)">{{ $t("edit") }}</button>
              <span v-else-if="props.column.field == 'alarm_reminder'">
                <span v-if="props.row.alarm_reminder == true">{{ $t("alarm_reminder_page.yes") }}</span>
                <span v-else>{{ $t("alarm_reminder_page.no") }}</span>
              </span>
              <span v-else-if="props.column.field == 'notification_schedule'">
                <span v-if="props.row.alarm_reminder == true">
                  <span v-if="props.row.notification_schedule == 1">{{ $t("alarm_reminder_page.every_month") }}</span>
                  <span v-else-if="props.row.notification_schedule == 2">{{ $t("alarm_reminder_page.every_other_month") }}</span>
                  <span v-else-if="props.row.notification_schedule == 3">{{ $t("alarm_reminder_page.each_quarter") }}</span>
                  <span v-else-if="props.row.notification_schedule == 4">{{ $t("alarm_reminder_page.first_monday_of_every_month") }}</span>
                  <span v-else-if="props.row.notification_schedule == 5">{{ $t("alarm_reminder_page.every_six_months") }}</span>
                </span>
              </span>
              <span v-else-if="props.column.field == 'name'">
                <router-link class="font-sans text-primary-500 underline hover:text-primary-600 transition duration-200" :to="'/alarm-reminder/unit/' + props.row.unit_id">{{ props.row.name }}</router-link>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          <vue-good-table
            v-if="toggle_log"
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="columns_log"
            :rows="rows_log"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: 20,
              perPageDropdown: [20, 50, 100],
              dropdownAllowAll: false,
            }"
          >
          </vue-good-table>
        </div>
      </div>
    </div>
    <!-- email reminder settings -->
    <modal name="email-reminder" class="modal-inner modal-email-setting" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
      <span class="close-button" @click="hide('email-reminder')"><BaseIcon icon="times-circle" class="text-white"/></span>
      <div class="modal-header">
        {{ $t("alarm_reminder_page.email_setting") }}
      </div>
      <div class="modal-body" v-if="update_data">
        <ValidationObserver ref="formEmailReminder">
          <div class="flex flex-wrap">
            <div class="w-full px-2">
              <BaseInput v-model="update_data.reminder_email" type="text" field_name="Email" placeholder="john@example.com, alice@example.com" rules="required" />
              <div class="text-xs italic font-normal">{{ $t("alarm_reminder_page.email_Enter_multiple") }}</div>
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-3"></div>

          <div class="flex flex-wrap px-2">
            <div class="w-full my-2">
              <label class="inline-flex items-center">
                <input type="checkbox" class="form-checkbox h-4 w-4 cursor-pointer text-sm" v-model="update_data.alarm_reminder" /><span class="ml-4"
                  ><b>{{ $t("alarm_reminder_page.receive_email_notification") }}</b></span
                >
              </label>
            </div>
          </div>

          <div class="flex flex-wrap px-2 mt-3" v-if="update_data.alarm_reminder">
            <div class="w-full text-lg font-bold font-serif" v-if="update_data.alarm_reminder">{{ $t("alarm_reminder_page.notification_time") }}</div>
            <div class="w-full lg:w-1/2 inline-flex items-center my-2">
              <input type="radio" class="h-5 w-5 cursor-pointer" v-model="update_data.notification_schedule" value="1" /><span class="ml-4">{{ $t("alarm_reminder_page.every_month") }}</span>
            </div>
            <div class="w-full lg:w-1/2 inline-flex items-center my-2">
              <input type="radio" class="h-5 w-5 cursor-pointer" v-model="update_data.notification_schedule" value="2" /><span class="ml-4">{{ $t("alarm_reminder_page.every_other_month") }}</span>
            </div>
            <div class="w-full lg:w-1/2 inline-flex items-center my-2">
              <input type="radio" class="h-5 w-5 cursor-pointer" v-model="update_data.notification_schedule" value="3" /><span class="ml-4">{{ $t("alarm_reminder_page.each_quarter") }}</span>
            </div>
            <div class="w-full lg:w-1/2 inline-flex items-center my-2">
              <input type="radio" class="h-5 w-5 cursor-pointer" v-model="update_data.notification_schedule" value="4" /><span class="ml-4">{{ $t("alarm_reminder_page.first_monday_of_every_month") }}</span>
            </div>
            <div class="w-full lg:w-1/2 inline-flex items-center my-2">
              <input type="radio" class="h-5 w-5 cursor-pointer" v-model="update_data.notification_schedule" value="5" /><span class="ml-4">{{ $t("alarm_reminder_page.every_six_months") }}</span>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <div class="modal-footer">
        <div class="footer-container clearfix">
          <button class="btn-red-outline px-3 py-2 float-left" @click="hide('email-reminder')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
          <button class="btn-green px-3 py-2 float-right" @click="updateData()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
const MODAL_WIDTH = 700;

export default {
  name: "AlarmReminderCustomer",
  title() {
    return this.$t("page_titles.alarm_reminder");
  },
  data() {
    return {
      customer_id: this.$route.params.customer_id,
      toggle_log: false,
      customers: [],
      units: [],
      rows: [],
      rows_log: [],
      update_data: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    columns() {
      return [
        { label: this.$t("alarm_reminder_table.operation"), field: "name", thClass: "text-sm" },
        { label: this.$t("alarm_reminder_table.email_reminder"), field: "alarm_reminder", thClass: "text-sm" },
        { label: this.$t("alarm_reminder_table.reminder_interval"), field: "notification_schedule", thClass: "text-sm" },
        { label: this.$t("alarm_reminder_table.email_contact"), field: "reminder_email", sortable: false, thClass: "text-sm" },
        { label: this.$t("alarm_reminder_table.latest_test_alarm"), field: "last_test_alarm", formatFn: this.formatDate, thClass: "text-sm" },
        { label: this.$t("alarm_reminder_table.activate"), field: "activate", sortable: false, thClass: "text-sm" },
      ];
    },
    columns_log() {
      return [
        { label: this.$t("columns_log.operation"), field: "unit_name", thClass: "text-sm" },
        { label: this.$t("columns_log.report"), field: "report", sortable: false, thClass: "text-sm" },
        { label: this.$t("columns_log.time_stamp"), field: "createdAt", formatFn: this.formatDate, thClass: "text-sm" },
      ];
    },
  },

  methods: {
    async getTestAlarmLogs() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/customer/test-alarm-log/${this.customer_id}`);
        this.customers = response.data.customers;
        this.units = response.data.units;
        this.rows = this.units;
        this.rows_log = response.data.test_alarm_logs;

        if (this.customers.length == 1) this.setPageTitle(`${this.customers[0].name} - ${this.$t("top_titles.alarm_reminder")}`, "alarm_reminder_customer");
        else this.setPageTitle(this.$t("top_titles.alarm_reminder"), "alarm_reminder_customer");
      } catch (error) {
        this.handleError(error);
      }
    },

    async updateData() {
      this.$refs.formEmailReminder.validate().then(async (success) => {
        if (!success) {
          return;
        }

        let data = {
          reminder_email: this.update_data.reminder_email,
          alarm_reminder: this.update_data.alarm_reminder,
          notification_schedule: this.update_data.notification_schedule,
        };

        try {
          // eslint-disable-next-line
          let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/units/${this.update_data._id}`, data);
          this.getTestAlarmLogs();

          this.hide("email-reminder");
        } catch (error) {
          this.handleError(error);
        }
      });
    },

    formatDate(date) {
      if (date == null) return "-";
      else return this.moment(date).format("YYYY-MM-DD");
    },

    show_unit(id, unit) {
      this.update_data = _.cloneDeep(unit);
      this.$modal.show(id);
    },

    hide(id) {
      this.update_data = null;
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },

  mounted() {
    this.getTestAlarmLogs();
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.alarm_reminder");
      this.setPageTitle(this.$t("top_titles.alarm_reminder"), "alarm_reminder_customer");
    },
  },
};
</script>
